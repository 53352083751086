.float-left {
    width: 15%;
    margin: 2% 0% 0% -5%;
}

.learning{
	margin: 1% 0% 0% -9%;

}

.login{
	font-weight: 600;
	font-size: 35px;
}

h6{
	color:  #949393;
}
.top{
	margin-top: 6%
}

.right{
	border-style: ridge;
    border-width: 1px;
    padding-top: 24%;
    padding-bottom: 24%
}

.btn{
	margin-top: 5%;
	width: 27%;
    border-style:solid;
}